// src/hooks/useResponsive.js
import { ref, onMounted, onUnmounted } from 'vue';

export function useResponsive() {
  const isMobile = ref(false);
  const isTablet = ref(false);
  const isDesktop = ref(false);

  const updateDevice = () => {
    isMobile.value = window.innerWidth <= 767;
    isTablet.value = window.innerWidth >= 768 && window.innerWidth <= 1023;
    isDesktop.value = window.innerWidth >= 1024;
  };

  onMounted(() => {
    updateDevice();
    window.addEventListener('resize', updateDevice);
  });

  onUnmounted(() => {
    window.removeEventListener('resize', updateDevice);
  });

  return { isMobile, isTablet, isDesktop };
}
