import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';

const TokenKey = 'warranty-token';
const UserKey = 'warranty-user';
const MenuKey = 'warranty-menu';

// 密钥，用于加密和解密（应避免硬编码在代码中，最好动态生成）
const secretKey = 'warranty-user-secret-key'; // 你可以改成更强的密钥

// 加密
function encrypt(data) {
  return CryptoJS.AES.encrypt(data, secretKey).toString();
}

// 解密
function decrypt(data) {
  const bytes = CryptoJS.AES.decrypt(data, secretKey);
  return bytes.toString(CryptoJS.enc.Utf8); // 转换为 UTF-8 字符串
}

// token 相关
export function getToken() {
  const encryptedToken = Cookies.get(TokenKey);
  if (encryptedToken) {
    return decrypt(encryptedToken); // 解密 Token
  }
  return null;
}

export function setToken(token) {
  const encryptedToken = encrypt(token); // 加密 Token
  return Cookies.set(TokenKey, encryptedToken);
}

export function removeToken() {
  return Cookies.remove(TokenKey);
}

// 存储用户相关信息
export function setUser(user) {
  const userData = typeof user === 'object' ? JSON.stringify(user) : user;
  const encryptedUser = encrypt(userData); // 加密用户信息
  return Cookies.set(UserKey, encryptedUser);
}

export function clearUser() {
  return Cookies.remove(UserKey);
}

export function getUser() {
  const encryptedUser = Cookies.get(UserKey);
  if (encryptedUser) {
    const decryptedUser = decrypt(encryptedUser); // 解密用户信息
    try {
      return JSON.parse(decryptedUser);
    } catch (error) {
      return decryptedUser; // 如果不是 JSON 格式，直接返回解密后的字符串
    }
  }
  return null;
}

// menu 相关
export function setMenu(menu) {
  const menuData = typeof menu === 'object' ? JSON.stringify(menu) : menu;
  const encryptedMenu = encrypt(menuData); // 加密菜单信息
  return Cookies.set(MenuKey, encryptedMenu);
}

export function clearMenu() {
  return Cookies.remove(MenuKey);
}

export function getMenu() {
  const encryptedMenu = Cookies.get(MenuKey);
  if (encryptedMenu) {
    const decryptedMenu = decrypt(encryptedMenu); // 解密菜单信息
    try {
      return JSON.parse(decryptedMenu);
    } catch (error) {
      return decryptedMenu; // 如果不是 JSON 格式，直接返回解密后的字符串
    }
  }
  return null;
}
