// src/utils/theme.js

// 存储主题到 localStorage 的键名
const THEME_STORAGE_KEY = 'user-theme';

// 生成浅色的 hover 颜色
function generateHoverColor(color) {
  return color + '99'; // 简单处理，添加透明度
}

// 应用主题颜色到 CSS 变量
function applyThemeColors({ primaryColor, secondaryColor }) {
  document.documentElement.style.setProperty('--primary-color', primaryColor);
  document.documentElement.style.setProperty(
    '--primary-color-hover',
    generateHoverColor(primaryColor)
  );
  document.documentElement.style.setProperty(
    '--secondary-color',
    secondaryColor
  );
}

// 从 localStorage 加载主题
function loadTheme() {
  const savedTheme = localStorage.getItem(THEME_STORAGE_KEY);
  return savedTheme ? JSON.parse(savedTheme) : null;
}

// 保存主题到 localStorage
function saveTheme(theme) {
  localStorage.setItem(THEME_STORAGE_KEY, JSON.stringify(theme));
}

// 初始化主题颜色
function initTheme(defaultTheme) {
  const savedTheme = loadTheme();
  const theme = savedTheme || defaultTheme;

  // 设置默认值到 CSS 变量
  applyThemeColors(theme);

  // 如果没有存储用户主题，保存默认主题到 localStorage
  if (!savedTheme) saveTheme(theme);
}

// 更新主题颜色
function updateTheme(newTheme) {
  applyThemeColors(newTheme);
  saveTheme(newTheme);
}

export { initTheme, updateTheme, loadTheme, saveTheme };
