import { createStore } from 'vuex';

// 自动导入 modules 文件夹中的所有模块
const files = require.context('./modules', false, /\.js$/);
const modules = {};

files.keys().forEach((key) => {
  const moduleName = key.replace(/(\.\/|\.js)/g, '');
  modules[moduleName] = files(key).default;
});

const store = createStore({
  state: {
    direction: 'forward', // 页面切换方向
  },
  getters: {
    userData(state) {
      return state.user ? state.user.user : null; // 确保 user 不为 undefined
    },
  },
  mutations: {
    updateDirection(state, direction) {
      state.direction = direction;
    },
  },
  actions: {},
  modules, // 注册模块
});

export default store;
