import { notification } from 'ant-design-vue';
// 重试次数和间隔
let refreshAttempts = 0;
const maxRefreshAttempts = 3;
const retryDelays = [1000, 3000, 5000]; // 重试间隔时间增加
// 正则用于匹配 chunk 加载错误
const patternError = /Loading chunk (\d)+ failed/g;
const patternError2 = /Loading (CSS|css) chunk (\d)+ failed/g;
const safeToString = (value) => {
  try {
    if (typeof value === 'string') {
      return value;
    } else if (value && typeof value.toString === 'function') {
      return value.toString();
    } else {
      return JSON.stringify(value) || String(value); // fallback to JSON.stringify or String
    }
  } catch (err) {
    return '[Error cannot be converted to string]';
  }
};
// 处理 chunk 加载错误
const handleChunkLoadError = (error, t) => {
  const errorMessage = safeToString(error.message) || safeToString(error);

  // 匹配 chunk 加载错误
  const isChunkLoadError =
    (errorMessage && errorMessage?.includes('chunkLoadError')) ||
    patternError.test(errorMessage) ||
    patternError2.test(errorMessage);

  if (isChunkLoadError) {
    if (refreshAttempts < maxRefreshAttempts) {
      console.log(
        `Chunk load error detected. Attempting to refresh... (Attempt ${
          refreshAttempts + 1
        })`
      );
      refreshAttempts++;

      setTimeout(() => {
        window.location.reload();
      }, retryDelays[refreshAttempts - 1]);
    } else {
      displayErrorMessage(t);
    }
  }
};

// 显示加载错误提示信息
const displayErrorMessage = (t) => {
  notification.error({
    message: t('warning.errorLoaingingResources'),
    description: t('warning.errorLoaingingTip'),
    duration: 5,
    placement: 'top',
  });
};

// 统一的错误处理逻辑
const handleError = (error, t) => {
  // 处理 chunk 加载错误
  handleChunkLoadError(error, t);

  if (!(error instanceof Error)) {
    // 如果不是 Error 对象，输出原始错误
    console.error('Unexpected error:', error);
    notification.error({
      message: t('warning.unexpectedError'),
      description: t('warning.unexpectedErrorTip'),
      duration: 5,
      placement: 'top',
    });
    return;
  }
};

export { handleError };
