var CryptoJS = require('crypto-js');

export function isPassWord(path) {
  const preg = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d$@$!%*#?&]{8,16}$/;
  return preg.test(path);
}

/**
 *加密处理，针对Login的密码进行加密
 */
export const encryption = (params) => {
  let { data, type, param, key } = params;
  const result = JSON.parse(JSON.stringify(data));
  if (type === 'Base64') {
    param.forEach((ele) => {
      result[ele] = btoa(result[ele]);
    });
  } else {
    param.forEach((ele) => {
      var data = result[ele];
      key = CryptoJS.enc.Latin1.parse(key);
      var iv = key;
      // 加密
      var encrypted = CryptoJS.AES.encrypt(data, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.ZeroPadding,
      });
      result[ele] = encrypted.toString();
    });
  }
  return result;
};

// 获取国家所属洲的方法
export const getContinentByCountryCode = async (isoCode) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(
        `https://restcountries.com/v3.1/alpha/${isoCode}`
      );
      const data = await response.json();
      // return data[0]?.continents[0] || 'Unknown';
      resolve(data[0]?.continents[0] || 'Unknown');
    } catch (error) {
      console.error('Error fetching continent:', error);
      // return 'Unknown';
      reject('Error fetching continent');
    }
  });
};
