// src/utils/language.js

// 存储主题到 localStorage 的键名
const OFFICIAL_WEBSITE_SOURCE = 'officialWebsiteSource';

//存储当前官网来源
function saveOfficialWebsiteSource(source) {
  localStorage.setItem(OFFICIAL_WEBSITE_SOURCE, JSON.stringify(source));
}

//获取当前官网来源
function getOfficialWebsiteSource() {
  return JSON.parse(localStorage.getItem(OFFICIAL_WEBSITE_SOURCE)) || '';
}

export { saveOfficialWebsiteSource, getOfficialWebsiteSource };
