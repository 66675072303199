import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import 'normalize.css/normalize.css';
import 'lib-flexible/flexible';
import { initTheme } from '@/utils/theme';
import setupRouteGuard from './router/permission'; // 引入路由守卫
import store from './store';
import Antd from 'ant-design-vue';
import i18n from './i18n';
import { handleError } from '@/utils/errorHandler'; // 引入错误处理模块
import '@/utils/updateCheck';
const { t } = i18n.global; // 获取 t 函数
const app = createApp(App);

// 监听全局错误事件
window.addEventListener(
  'error',
  (event) => {
    const error = event.error || event;
    handleError(error, t);
  },
  true
);

// 监听未处理的 Promise 错误
window.addEventListener('unhandledrejection', (event) => {
  const error = event.reason || event;
  handleError(error, t);
});

// 初始化应用
function setupApp() {
  // 初始化主题
  // initTheme(store.state.theme);
  setupRouteGuard(); // 激活全局路由守卫

  app.use(router);
  app.use(i18n);
  app.use(store);
  app.use(Antd);
  app.mount('#app');
}

setupApp();
