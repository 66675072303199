import http from '@/utils/request';
import { stringify } from 'qs';
import { encryption } from '@/utils/utils';
import axios from 'axios';
const contentPath = '/open-api';
//登录
export function login(data) {
  const user = encryption({
    data: data,
    key: 'pigxpigxpigxpigx',
    param: ['password'],
  });
  return http({
    url: `auth/v1/login`,
    method: 'post',
    data: user,
    headers: {
      Authorization: 'Basic YXBwOmFwcA==',
    },
  });
}

export function getInfo(token) {
  return http({
    url: '/user/info',
    method: 'get',
    params: { token },
  });
}

//登出
export function logout() {
  return http({
    url: 'auth/v1/logout',
    method: 'get',
  });
}

//登出
export function deleteAccount(params) {
  return http({
    url: `/admin/v1/destroyUser?${stringify(params)}`,
    method: 'DELETE',
  });
}

//注册接口
export function register(data) {
  const user = encryption({
    data: data,
    key: 'pigxpigxpigxpigx',
    param: ['password'],
  });
  return http({
    url: 'auth/v1/register',
    method: 'post',
    data: data,
    headers: {
      Authorization: 'Basic YXBwOmFwcA==',
    },
  });
}

//发送验证码接口
export function sendCaptchaAPI(data) {
  return http({
    url: 'v1/mail-code',
    method: 'get',
    params: data,
  });
}

//重置密码
export function resetPassword(data) {
  return http({
    url: '/admin/v1/initPassword',
    method: 'put',
    data: data,
    headers: {
      Authorization: 'Basic YXBwOmFwcA==',
    },
  });
}
