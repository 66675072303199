// src/store/modules/language.js
const state = {
  currentLanguage: localStorage.getItem('language') || '',
};

const mutations = {
  SET_LANGUAGE(state, lang) {
    state.currentLanguage = lang;
    // 更新并保存新的语言设置到 localStorage
    localStorage.setItem('language', lang);
  },
};

const actions = {
  setLanguage({ commit }, lang) {
    commit('SET_LANGUAGE', lang);
  },
};

const getters = {
  currentLanguage: (state) => state.currentLanguage,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
