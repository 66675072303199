// src/router/permission.js
import { getToken } from '@/utils/auth'; // 假设你有这个方法
import router from './index'; // 引入 router 实例

// 获取第一个有权限的页面
function getFirstAuthorizedPage() {
  const accessibleRoutes = router.getRoutes().filter((route) => {
    return !(route.meta?.hideMenu || route.meta?.isResponsive);
  });

  return accessibleRoutes.length > 0 ? accessibleRoutes[0].path : '/warranty';
}

// 设置全局路由守卫
function setupRouteGuard() {
  router.beforeEach((to, from, next) => {
    const token = getToken();

    if (to.path === '/login') {
      if (token) {
        // 如果已登录，跳转到第一个有权限的页面
        next(getFirstAuthorizedPage());
      } else {
        // 如果没有 token，继续停留在登录页
        next();
      }
    } else if (to.meta?.requiresAuth) {
      // 如果路由需要权限且没有 token，则跳转到登录页
      if (token) {
        next(); // 已登录，放行
      } else {
        next('/login'); // 未登录，跳转到登录页
      }
    } else {
      // 如果是其他页面，不需要权限控制，直接放行
      next();
    }
  });
}

export default setupRouteGuard;
