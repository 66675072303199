// src/store/modules/auth.js
import {
  getToken,
  setToken,
  removeToken,
  setUser,
  clearUser,
  getUser,
  clearMenu,
  setMenu,
  getMenu,
} from '@/utils/auth';

const state = {
  user: null, // 用户信息对象
  token: getToken(),
};

const mutations = {
  SET_USER(state, user) {
    state.user = user;
  },
  CLEAR_USER(state) {
    state.user = null;
    state.token = null;
    state.menu = null;
  },
  SET_TOKEN(state, token) {
    state.token = token;
  },
  SET_MENU(state, menu) {
    state.menu = menu;
  },
};

const actions = {
  // 保存用户信息
  saveUser({ commit }, user) {
    commit('SET_USER', user);
    setUser(user);
  },
  //保存用户的token
  saveToken({ commit }, token) {
    commit('SET_TOKEN', token);
    setToken(token);
  },

  //保存menu信息
  saveMenu({ commit }, menu) {
    commit('SET_MENU', menu);
    setMenu(menu);
  },

  // 清除用户信息
  removeUser({ commit }) {
    commit('CLEAR_USER');
    removeToken();
    clearUser();
    clearMenu();
  },

  // 获取用户信息
  loadUser({ commit }) {
    const token = getToken();
    if (token) {
      // 这里假设有一个获取用户信息的 API 调用，可以根据实际需要调整
      return fetchUserInfo(token).then((user) => {
        commit('SET_USER', user);
      });
    }
  },
};

const getters = {
  isAuthenticated: (state) => !!state.token,
  user: (state) => state.user,
  token: (state) => state.token,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
