// src/router/index.js
import { createRouter, createWebHistory } from 'vue-router';
// import GuidePage from '../components/GuidePage.vue';

const routes = [
  {
    path: '/',
    redirect: '/warranty',
    meta: {
      title: 'Guide Page',
      icon: 'home-o',
      label: 'Home',
      requiresAuth: true,
    },
  },
  {
    path: '/login',
    meta: {
      title: 'Login',
      icon: 'home-o',
      label: 'Login',
      isResponsive: false,
      hideMenu: true,
      requiresAuth: false,
    },
    component: () => import('@/pages/Login/LoginPage.vue'),
  },
  {
    path: '/forgetPassword',
    meta: {
      title: 'Forget Password',
      icon: 'home-o',
      label: 'Forget Password',
      isResponsive: false,
      hideMenu: true,
      requiresAuth: false,
    },
    component: () => import('@/pages/Login/ForgetPassword.vue'),
  },
  {
    path: '/register',
    meta: {
      title: 'Register',
      icon: 'home-o',
      label: 'Register',
      isResponsive: false,
      hideMenu: true,
      requiresAuth: false,
    },
    component: () => import('@/pages/Login/CreateAccount.vue'),
  },
  {
    path: '/warranty',
    component: () => import('@/pages/Warranty/DeviceList.vue'),
    meta: {
      title: 'Guide steps',
      icon: 'AppstoreAddOutlined',
      label: 'route.deviceList',
      keepAlive: true,
      requiresAuth: true,
    },
  },
  {
    path: '/warrantyDetail',
    component: () => import('@/pages/Warranty/ProductDetails.vue'),
    name: 'ProductDetails',
    meta: {
      title: 'Guide steps',
      icon: 'guide-o',
      label: 'Guide',
      keepAlive: true,
      hideMenu: true,
      requiresAuth: true,
    },
  },
  // {
  //   path: '/home',
  //   component: () => import('@/pages/Theme/index.vue'),
  //   meta: {
  //     title: 'Guide steps',
  //     icon: 'guide-o',
  //     label: 'route.home',
  //     keepAlive: true,
  //   },
  // },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
