// updateCheck.js

// 定义定时器变量
let updateCheckInterval;
let loadEventListenerAdded = false; // 用于标记是否已经添加了 load 监听器

function checkForUpdates() {
  fetch('/index.html', {
    cache: 'no-store', // 强制不使用缓存
  })
    .then((response) => {
      console.log('Fetch status:', response.status); // 检查响应状态
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.text();
    })
    .then((html) => {
      // 创建一个DOMParser来解析HTML
      const parser = new DOMParser();
      const doc = parser.parseFromString(html, 'text/html');

      // 获取新的 JS 和 CSS 文件名
      const newJsFiles = doc.querySelectorAll('script[src]');
      const newCssFiles = doc.querySelectorAll('link[rel="stylesheet"]');

      let newAppJs = '';
      let newChunkJs = '';
      let newCssFile = '';

      newJsFiles.forEach((tag) => {
        const src = tag.getAttribute('src');
        console.log('New JS file:', src); // 打印每个新的 JS 文件路径
        if (src.match(/js\/app\.\w+\.js/)) {
          newAppJs = src;
        } else if (src.match(/js\/chunk-vendors\.\w+\.js/)) {
          newChunkJs = src;
        }
      });

      newCssFiles.forEach((tag) => {
        const href = tag.getAttribute('href');
        console.log('New CSS file:', href); // 打印每个新的 CSS 文件路径
        if (href.match(/css\/app\.\w+\.css/)) {
          newCssFile = href;
        }
      });

      // 获取当前页面中的 JS 和 CSS 文件名
      const currentJsFiles = document.querySelectorAll('script[src]');
      const currentCssFiles = document.querySelectorAll(
        'link[rel="stylesheet"]'
      );

      let currentAppJs = '';
      let currentChunkJs = '';
      let currentCssFile = '';

      currentJsFiles.forEach((tag) => {
        const src = tag.getAttribute('src');
        console.log('Current JS file:', src); // 打印每个当前 JS 文件路径
        if (src.match(/js\/app\.\w+\.js/)) {
          currentAppJs = src;
        } else if (src.match(/js\/chunk-vendors\.\w+\.js/)) {
          currentChunkJs = src;
        }
      });

      currentCssFiles.forEach((tag) => {
        const href = tag.getAttribute('href');
        console.log('Current CSS file:', href); // 打印每个当前 CSS 文件路径
        if (href.match(/css\/app\.\w+\.css/)) {
          currentCssFile = href;
        }
      });

      // 修改本地开发环境的判断逻辑
      const isLocalEnv =
        (!currentAppJs && !currentChunkJs) || // 当前页面未加载任何带哈希值的 JS
        (currentAppJs && currentAppJs.endsWith('/js/app.js')); // 或者 app.js 是未哈希的形式

      // 如果在本地开发环境，清除定时器并移除 load 监听器
      if (isLocalEnv) {
        console.log('本地开发环境，跳过更新检查。');
        if (updateCheckInterval) {
          clearInterval(updateCheckInterval); // 清除定时器
          updateCheckInterval = null; // 防止重复清除
        }
        if (loadEventListenerAdded) {
          window.removeEventListener('load', initUpdateCheck); // 移除 load 监听器
          loadEventListenerAdded = false; // 更新标记
        }
        return;
      }

      // 比较哈希值，判断是否有更新
      const hasUpdate =
        (newAppJs && currentAppJs && newAppJs !== currentAppJs) ||
        (newChunkJs && currentChunkJs && newChunkJs !== currentChunkJs) ||
        (newCssFile && currentCssFile && newCssFile !== currentCssFile);

      if (hasUpdate) {
        console.log('新版本已发布，重新加载页面');
        window.location.reload();
      } else {
        console.log('当前是最新版本，无需更新。');
      }
    })
    .catch((error) => console.error('Error checking for updates:', error));
}

// 初始化更新检查函数
function initUpdateCheck() {
  if (!updateCheckInterval) {
    checkForUpdates();
    updateCheckInterval = setInterval(checkForUpdates, 5 * 60 * 1000);
    loadEventListenerAdded = true;
  }
}

// 页面加载时触发初始化函数
if (!loadEventListenerAdded) {
  window.addEventListener('load', initUpdateCheck);
  loadEventListenerAdded = true;
}

// 导出函数以供外部使用
export { initUpdateCheck, checkForUpdates };
