// src/store/modules/theme.js
import { updateTheme, loadTheme } from '@/utils/theme';

const state = loadTheme() || {
  primaryColor: '#ffffff',
  secondaryColor: '#35495e',
};

const mutations = {
  SET_PRIMARY_COLOR(state, color) {
    state.primaryColor = color;
  },
  SET_SECONDARY_COLOR(state, color) {
    state.secondaryColor = color;
  },
};

const actions = {
  setPrimaryColor({ commit, state }, color) {
    commit('SET_PRIMARY_COLOR', color);
    updateTheme(state); // 更新并保存新的主题
  },
  setSecondaryColor({ commit, state }, color) {
    commit('SET_SECONDARY_COLOR', color);
    updateTheme(state); // 更新并保存新的主题
  },
};

const getters = {
  primaryColor: (state) => state.primaryColor,
  secondaryColor: (state) => state.secondaryColor,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
